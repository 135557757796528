function host() {
    if ((process.env.NODE_ENV == "development")) {
        //开发环境
        return "http://www.handebook.com:81";
    } else {
        //生产地址
        if (window.location.protocol == 'https:') {
            return "https://www.handebook.com";
        }
        else {
            return "http://www.handebook.com";
        }
    }
}

export default {
    user: {
        login: `${host()}/api/web/user/login`,
        logout: `${host()}/api/web/user/logout`,
        info: `${host()}/api/web/user/info`,
    },
    account: {
        documentList: `${host()}/api/web/account/document/list`,
        admitReportList: `${host()}/api/web/account/admit/report/list`,
        admitReportDownload: `${host()}/api/web/account/admit/report/download`,
        aiSelectionReportList: `${host()}/api/web/account/aiSelection/report/list`,
        aiSelectionReportDownload: `${host()}/api/web/account/aiSelection/report/download`,
        smartAdjustReportList: `${host()}/api/web/account/smartAdjust/report/list`,
        smartAdjustReportDownload: `${host()}/api/web/account/smartAdjust/report/download`,
    },
    proxy: {
        image: `${host()}/api/web/proxy/image`,
        info: `${host()}/api/web/proxy/info`,
        contactInfo: `${host()}/api/web/proxy/contactInfo`,
        menuPermissionList: `${host()}/api/web/proxy/menuPermission/list`,
        onlinePaymentVisible: `${host()}/api/web/proxy/onlinePayment/visible`,
        printerQrCodeVisible: `${host()}/api/web/proxy/printerQrCode/visible`,
        relatedCourseVisible: `${host()}/api/web/proxy/relatedCourse/visible`,
        isDocin: `${host()}/api/web/proxy/isDocin`,
    },
    order: {
        list: `${host()}/api/web/order/list`,
        info: `${host()}/api/web/order/info`,
        delete: `${host()}/api/web/order/delete`,
        cancel: `${host()}/api/web/order/cancel`,
    },
    pay: {
        url: `${host()}/api/web/pay/url`,
        info: `${host()}/api/web/pay/info`,
        state: `${host()}/api/web/pay/state`,
    },
    sms: {
        sendCommonMessage: `${host()}/api/web/sms/common`,
        sendLoginMessage: `${host()}/api/web/sms/login`,
    },
    record: {
        visitPopularize: `${host()}/api/web/record/popularize/visit`,
        visitWebsite: `${host()}/api/web/record/website/visit`,
        visitProduct: `${host()}/api/web/record/product/visit`,
        visitSchool: `${host()}/api/web/record/school/visit`,
    },
    member: {
        list: `${host()}/api/web/member/list`,
        purchased: `${host()}/api/web/member/purchased`,
        submitOrder: `${host()}/api/web/member/order/submit`,
    },
    school: {
        logo: `${host()}/api/web/school/logo`,
        list: `${host()}/api/web/school/list`,
        details: `${host()}/api/web/school/details`,
        schoolInfo: `${host()}/api/web/school/info`,
        collegeInfo: `${host()}/api/web/school/college/info`,
        majorInfo: `${host()}/api/web/school/major/info`,
        related: {
            examDocumentList: `${host()}/api/web/school/related/examDocument/list`,
            firstDocumentList: `${host()}/api/web/school/related/firstDocument/list`,
            secondDocumentList: `${host()}/api/web/school/related/secondDocument/list`,
            courseList: `${host()}/api/web/school/related/course/list`,
            customList: `${host()}/api/web/school/related/custom/list`,
            admitList: `${host()}/api/web/school/related/admit/list`,
        },
    },
    major: {
        groupList: `${host()}/api/web/major/group/list`,
        groupInfo: `${host()}/api/web/major/group/info`,
        schoolList: `${host()}/api/web/major/school/list`,
        filter: {
            belongCategoryList: `${host()}/api/web/major/filter/belongCategory/list`,
            firstDisciplineList: `${host()}/api/web/major/filter/belongFirstDiscipline/list`,
        },
    },
    document: {
        download: `${host()}/api/web/document/download`,
        count: `${host()}/api/web/document/count`,
        list: `${host()}/api/web/document/list`,
        info: `${host()}/api/web/document/info`,
        state: `${host()}/api/web/document/state`,
        password: `${host()}/api/web/document/password`,
        purchased: `${host()}/api/web/document/purchased`,
        submitOrder: `${host()}/api/web/document/order/submit`,
        filter: {
            schoolList: `${host()}/api/web/document/filter/school/list`,
            collegeList: `${host()}/api/web/document/filter/college/list`,
            majorList: `${host()}/api/web/document/filter/major/list`,
            firstSubjectList: `${host()}/api/web/document/filter/firstSubject/list`,
            secondSubjectList: `${host()}/api/web/document/filter/secondSubject/list`,
        },
        related: {
            examDocumentList: `${host()}/api/web/document/related/examDocument/list`,
            firstDocumentList: `${host()}/api/web/document/related/firstDocument/list`,
            secondDocumentList: `${host()}/api/web/document/related/secondDocument/list`,
            courseList: `${host()}/api/web/document/related/course/list`,
            customList: `${host()}/api/web/document/related/custom/list`,
        },
        print: {
            info: `${host()}/api/web/document/print/info`,
            update: `${host()}/api/web/document/print/update`,
        },
        major: {
            info: `${host()}/api/web/document/major/info`,
        }
    },
    course: {
        lessonVideo: `${host()}/api/web/course/lesson/video`,
        lectureFile: `${host()}/api/web/course/lecture/file`,
        coverImage: `${host()}/api/web/course/cover/image`,
        introImage: `${host()}/api/web/course/intro/image`,
        directoryList: `${host()}/api/web/course/directory/list`,
        courseList: `${host()}/api/web/course/list`,
        courseInfo: `${host()}/api/web/course/info`,
        groupList: `${host()}/api/web/course/group/list`,
        lessonList: `${host()}/api/web/course/lesson/list`,
        lectureList: `${host()}/api/web/course/lecture/list`,
        purchased: `${host()}/api/web/course/purchased`,
        submitOrder: `${host()}/api/web/course/order/submit`,
    },
    article: {
        image: `${host()}/api/web/article/image`,
        list: `${host()}/api/web/article/list`,
        info: `${host()}/api/web/article/info`,
    },
    adjust: {
        list: `${host()}/api/web/adjust/list`,
        info: `${host()}/api/web/adjust/info`,
        statistic: `${host()}/api/web/adjust/statistic`,
        schoolInfo: `${host()}/api/web/adjust/school/info`,
        majorInfo: `${host()}/api/web/adjust/major/info`,
        filter: {
            belongCategoryList: `${host()}/api/web/adjust/filter/belongCategory/list`,
            firstDisciplineList: `${host()}/api/web/adjust/filter/belongFirstDiscipline/list`,
            majorCodeNameList: `${host()}/api/web/adjust/filter/majorCodeName/list`,
        },
        related: {
            secondDocumentList: `${host()}/api/web/adjust/related/secondDocument/list`,
            admitList: `${host()}/api/web/adjust/related/admit/list`,
        },
    },
    admit: {
        admitRosterImage: `${host()}/api/web/admit/admitRoster/image`,
        adjustRosterImage: `${host()}/api/web/admit/adjustRoster/image`,
        downloadFile: `${host()}/api/web/admit/file/download`,
        majorCount: `${host()}/api/web/admit/major/count`,
        majorList: `${host()}/api/web/admit/major/list`,
        schoolInfo: `${host()}/api/web/admit/school/info`,
        collegeInfo: `${host()}/api/web/admit/college/info`,
        majorInfo: `${host()}/api/web/admit/major/info`,
        admitList: `${host()}/api/web/admit/list`,
        fileList: `${host()}/api/web/admit/file/list`,
        statistic: `${host()}/api/web/admit/statistic`,
        adjustDirectionList: `${host()}/api/web/admit/adjustDirection/list`,
        reportGenerate: `${host()}/api/web/admit/report/generate`,
        filter: {
            schoolList: `${host()}/api/web/admit/filter/school/list`,
            collegeList: `${host()}/api/web/admit/filter/college/list`,
            majorList: `${host()}/api/web/admit/filter/major/list`,
        },
        compare: {
            add: `${host()}/api/web/admit/compare/add`,
        },
    },
    smartAdjust: {
        adjustRosterImage: `${host()}/api/web/smartAdjust/adjustRoster/image`,
        majorCount: `${host()}/api/web/smartAdjust/major/count`,
        majorList: `${host()}/api/web/smartAdjust/major/list`,
        analysisResult: `${host()}/api/web/smartAdjust/analysisResult`,
        reportGenerate: `${host()}/api/web/smartAdjust/report/generate`,
        form: {
            schoolList: `${host()}/api/web/smartAdjust/form/school/list`,
            collegeList: `${host()}/api/web/smartAdjust/form/college/list`,
            majorList: `${host()}/api/web/smartAdjust/form/major/list`,
        },
        filter: {
            conditionList: `${host()}/api/web/smartAdjust/filter/condition/list`,
        },
        compare: {
            list: `${host()}/api/web/smartAdjust/compare/list`,
            add: `${host()}/api/web/smartAdjust/compare/add`,
            delete: `${host()}/api/web/smartAdjust/compare/delete`,
            clear: `${host()}/api/web/smartAdjust/compare/clear`,
        },
    },
    aiSelection: {
        majorCount: `${host()}/api/web/aiSelection/major/count`,
        majorList: `${host()}/api/web/aiSelection/major/list`,
        reportGenerate: `${host()}/api/web/aiSelection/report/generate`,
        filter: {
            belongCategoryList: `${host()}/api/web/aiSelection/filter/belongCategory/list`,
            firstDisciplineList: `${host()}/api/web/aiSelection/filter/belongFirstDiscipline/list`,
            majorCodeNameList: `${host()}/api/web/aiSelection/filter/majorCodeName/list`,
            firstSubjectList: `${host()}/api/web/aiSelection/filter/firstSubject/list`,
            researchDirectionList: `${host()}/api/web/aiSelection/filter/researchDirection/list`,
        },
        compare: {
            list: `${host()}/api/web/aiSelection/compare/list`,
            add: `${host()}/api/web/aiSelection/compare/add`,
            delete: `${host()}/api/web/aiSelection/compare/delete`,
            clear: `${host()}/api/web/aiSelection/compare/clear`,
        },
    },
    gradeRank: {
        count: `${host()}/api/web/gradeRank/count`,
        list: `${host()}/api/web/gradeRank/list`,
        add: `${host()}/api/web/gradeRank/add`,
        majorInfo: `${host()}/api/web/gradeRank/major/info`,
        filter: {
            schoolList: `${host()}/api/web/gradeRank/filter/school/list`,
            collegeList: `${host()}/api/web/gradeRank/filter/college/list`,
            majorList: `${host()}/api/web/gradeRank/filter/major/list`,
        },
        related: {
            secondDocumentList: `${host()}/api/web/gradeRank/related/secondDocument/list`,
            admitList: `${host()}/api/web/gradeRank/related/admit/list`,
        },
    },
    nationalLine: {
        trendList: `${host()}/api/web/nationalLine/trend/list`,
    },
}