import comm from "./comm"
import uuid from "./uuid"
import guid from "./guid"
import html from "./html"
import json from "./json"
import date from "./date"
import uri from "./uri"
import security from "./security"
import pinyin from "./pinyin"
import terminal from "./terminal"
import verify from "./verify"
import download from "./download"

export default {
    ...comm,
    ...uuid,
    ...guid,
    ...html,
    ...json,
    ...date,
    ...uri,
    ...security,
    ...pinyin,
    ...terminal,
    ...verify,
    ...download,
}